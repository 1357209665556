<template>
  <div class="app-container" @keydown.ctrl="handleKeydown">
    <!-- 凭证 -->
    <div class="big_box3">
      <!-- 顶部标题 -->
      <div class="top_title clearfix">
        <div class="radio_box">
          <el-radio-group v-model="radio">
            <el-radio :label="1">普通</el-radio>
            <el-radio :label="2">数量金额类</el-radio>
            <el-radio :label="3">外币</el-radio>
          </el-radio-group>
        </div>
        <h5>记账凭证</h5>
        <div class="bottom_time clearfix">
          <div class="top_input3 clearfix fl">
            <h6>记</h6>
            <el-input size="small" v-model="content.voucherNo" style="width:65px"></el-input>
            <span>号</span>
          </div>
          <div class="top_input2 clearfix fl">
            <h6>日期</h6>
            <el-date-picker :disabled-date="disabledDate" size="small" style="width:70%;display:inline-block" v-model="content.voucherDate" :picker-options="pickerOptions1" type="date" placeholder="选择日期时间" value-format="YYYY-MM-DD"></el-date-picker>
          </div>
        </div>
        <!-- <div class="center_title clearfix">
          <span>
            <el-input disabled v-model="year" style="width:40%;" size="small"></el-input>
          </span>
          <span>年</span>
          <span>
            <el-input disabled v-model="month" style="width:30%;" size="small"></el-input>
          </span>
          <span>期</span>
        </div> -->
        <div class="right_num clearfix">
          <span>附单据</span>
          <span>
            <el-input style="width:28%;" v-model="content.billCount" size="small"></el-input>
          </span>
          <span>张</span>
        </div>
      </div>
      <!-- 顶部标题 end -->
      <div class="content2 clearfix" v-auto-focus="focusCtrl" :data-current="currentIndex" :data-action="actionType">
        <div class="tops">
          <div style="left: -28px;" @click="additem(-1)" class="add">
            <i class="iconfont icon-jiahao addi"></i>
          </div>
          <span class="box_top" style="border-left: none">
            <h6>摘要</h6>
          </span>
          <span class="box_top">
            <h6>会计科目</h6>
          </span>
          <span v-if="radio == 3" class="box_top">
            <h6>币种</h6>
          </span>
          <span v-if="radio == 2" class="box_top">
            <h6>存货数量</h6>
          </span>
          <span class="box_top money_box_top">
            <div>借方金额</div>
            <div class="gsbqw">
              <div>百</div>
              <div>十</div>
              <div>亿</div>
              <div>千</div>
              <div>百</div>
              <div>十</div>
              <div>万</div>
              <div>千</div>
              <div>百</div>
              <div>十</div>
              <div>元</div>
              <div>角</div>
              <div>分</div>
            </div>
          </span>
          <span class="box_top money_box_top">
            <div>贷方金额</div>
            <div class="gsbqw">
              <div>百</div>
              <div>十</div>
              <div>亿</div>
              <div>千</div>
              <div>百</div>
              <div>十</div>
              <div>万</div>
              <div>千</div>
              <div>百</div>
              <div>十</div>
              <div>元</div>
              <div>角</div>
              <div>分</div>
            </div>
          </span>
          <!-- <div @click="additem()" class="add">
            <svg-icon icon-class="add" />
          </div>-->
        </div>
        <!-- for 循环 -->
        <div v-for="(item,indexBig) in items" :key="indexBig" class="bottom clearifx big_box">
          <div class="common_box" @click="huifu(indexBig,1)">
            <input v-model="item.summary" v-if="currentIndex == indexBig + '_1'" :data-index="indexBig + '_1'" class="infarm_box" size="small" @focus="focusEnter(item.summary)" @keyup.enter="enter"/>
            <span v-else style="word-break: break-all;height:100%;overflow: hidden;text-overflow: ellipsis;display: inline-block;-webkit-box-orient: vertical;">{{item.summary}}</span>

          </div>
          <div class="common_box big_boxcode" @click="huifu(indexBig,2)">
            <div class="common_box_div" > 
              <!-- <span> -->
                <!-- <el-select style="width:100%;border: 0;" v-model="item.subjectId" placeholder="请选择" @change="changeCode(item)">
                  <el-option v-for="(itemcode,index) in subjectList" :key="index" :label="`${itemcode.code}-${itemcode.name}`" :value="itemcode.id"></el-option>
                </el-select> -->
              <qzf-select v-if="currentIndex == indexBig + '_2'" :data-index="indexBig + '_2'" v-model:signName="item.signName" v-model:subjectId="item.subjectId" v-model:fzhsItemId="item.fzhsId" @success="changeCode($event, item)"></qzf-select> 
              <span v-else class="subjectWidth" style="white-space:break-spaces">{{item.signName}}</span>
             
              <span style="position: absolute;bottom: 0;left: 0;font-size: 10px;z-index:22">
              余额：
              <span v-if="item.balance<0" @click="jumpDetailAccount(item)"  style="color:#F15A24 !important;cursor: pointer;" class="money">{{item.balance}}</span>
              <span v-else class="money"  @click="jumpDetailAccount(item)" style="color:var(--themeColor,#17a2b8);cursor: pointer;">{{item.balance}}</span>
            </span>
            </div>
          </div>
          <!-- 新添加的 -->
          <!-- 汇率 -->
          <div v-if="radio == 3" class="common_box big_boxwb">
            <div class="top_item padding_sty">
              <el-select filterable default-first-option @change="changeWb(item.wb,item)" v-model="item.wb">
                <el-option v-for="itemwb in wbLists" :key="itemwb.type" :label="itemwb.type" :value="itemwb.type"></el-option>
              </el-select>
              <input @change="changeWbAmount(item)" v-model="item.wbAmount" class="top_input" placeholder="外币金额"/>
            </div>
            <div class="bottom_rate">
              <label>汇率：</label>
              <input @change="changeWbAmount(item)" v-model="item.rate" class="top_input"/>
            </div>
          </div>
          <!-- 汇率 end -->
          <!-- 存货 -->
          <div v-if="radio== 2" class="common_box">
            <div class="top_item" style="display:block">
              <input @change="changeCount(item)" v-model="item.count" class="infarm_box" placeholder=""/>
            </div>
          </div>
          <!-- 存货 end-->
          <!-- 新添加的 end-->
          <div class="text_big common_box td-money" @click="huifu(indexBig,9)">
            <div class="big_gsbqw" @click="showInput(item,'in')" :style="{letterSpacing:widthAmount + 'px'}" v-show="currentIndex != indexBig + '_9'">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <p v-if="item.inAmount"> {{(Number(item.inAmount).toFixed(2)*100).toFixed(0)}}</p>
            </div>
            <input v-show="currentIndex == indexBig + '_9'" :data-index="indexBig + '_9'" @change="changeInput(item,'in')" v-model="item.inAmount" type="text" @blur="inputBlur(item,'in')"  @keydown="balanceAmount($event,item,'in')" @keyup.enter="enter"/>
          </div>

          <div class="text_big common_box td-money" @click="huifu(indexBig,10)">

            <div class="big_gsbqw" @click="showInput(item,'out')" :style="{letterSpacing:widthAmount + 'px'}" v-show="currentIndex != indexBig + '_10'">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <p v-if="item.outAmount"> {{(Number(item.outAmount).toFixed(2)*100).toFixed(0)}}</p>
            </div>
            <input v-show="currentIndex == indexBig + '_10'" :data-index="indexBig + '_10'" @change="changeInput(item,'out')" v-model="item.outAmount" type="text" @blur="inputBlur(item,'out')" @keydown="balanceAmount($event,item,'out')" @keyup.enter="enter"/>
          </div>
          <!-- v-if="type === 'add'" -->
          <div class="close" @click="delitem(indexBig,item.id)">
            <!-- <svg-icon icon-class="del" /> -->
            <i class="iconfont icon-shanchu"></i>
          </div>
          <div style="left: -28px;" @click="additem(indexBig)" class="add">
            <i class="iconfont icon-jiahao addi"></i>
          </div>
        </div>

        <div class="bottom clearifx">
          <div class="common_box">
            <p>合计</p>
          </div>
          <div class="common_box">
            <p class="infarm_box">{{content.bigwrite}}</p>
          </div>
          <div v-if="radio == 2" class="common_box">
            <p class="infarm_box"></p>
          </div>
          <div v-if="radio == 3" class="common_box">
            <p class="infarm_box"></p>
          </div>
          <div class="text_big common_box td-money">
            <div>
              <span :style="{letterSpacing:widthAmount + 'px'}" v-if="content.inSumAmount">
                {{(Number(content.inSumAmount).toFixed(2)*100).toFixed(0)}}
              </span>
            </div>
          </div>

          <div class="text_big common_box td-money">
            <div>
              <span :style="{letterSpacing:widthAmount + 'px'}" v-if="content.outSumAmount">
                {{(Number(content.outSumAmount).toFixed(2)*100).toFixed(0)}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 凭证 end -->
    <div class="bottom_btn">
      <el-button @click="handleCreate()" v-loading.fullscreen.lock="fullscreenLoading" type="primary" size="small">保存</el-button>
      <qzf-button  style="float:right;margin-left:10px" type="primary" size="small" @success="handleCreate(content.voucherDate)">保存并新增</qzf-button>
    </div>
    <!-- 凭证 end -->
  </div>
</template>

<script>
import { hisVoucherInfo,saveHisVoucher ,getHisVoucherCodeInfo} from "@/api/old.js";
import { getNewVoucherNo } from "@/api/voucher.js";
import { wbList } from '@/api/subject.js'
import { parseTime } from "@/utils"


let data = ""
let dataLast = ""
export default {
  name: "addVoucher",
  components:{ },
  watch: {
    $route() {
      if(this.$route.path != "/bookkeeps/addVoucher"){
        return
      }
    }
  },
  mounted() {
    // data = new Date(this.nowTime)
    this.content.voucherDate = parseTime(new Date(this.nowTime),"{y}-{m}-{d}")
    this.getWbList()
  },
  data() {
    return {
      disabledDate:(time) => {
        let t = this.$store.getters['user/comInfo'].period
        let e = t.slice(0,4) + "-" + t.slice(4,6) + "-01"
        // //console.log(new Date(e).getTime(),'new Date(e).getTime()')1648771200000
        // 1648671200000
        return time >= new Date(this.$getLastDay(t)).getTime() || time <= new Date(e).getTime() - 100000000
      },
      showSelect: false,
      listQuery: {
        page: 1,
        limit: 20,
        sheetId: this.$store.getters["commons/params"].historyVoucherId,
        name:'',
        feeType:''
      },
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() >=  data || time.getTime() <= new Date(dataLast);
        },
      },
      templateType: 0,
      isInventoryBol: false,
      isInventoryBol5001: false,
      value: "",
      nowTime: "",
      addData: {
        code: "",
        name: "",
        radio: "1"
      },
      radio:1,
      subjectList: [],
      fullscreenLoading: false,
      month: "",
      year: "",
      subject: "",
      type: "",
      // value: "",
      content: {
        inSumAmount: 0,
        outSumAmount: 0,
        bigwrite: "", //大写合计
        voucherDate: new Date(this.nowTime), //时间
        voucherNo: "", //凭证号
        billCount: 1
      },
      items: [
      {
          wbStatus: false,
          chStatus: false,
          type: 0,
          summary: "",
          subjectId: 0,
          fzhsId: 0,
          signName: "",
          outAmount: "",
          inAmount: "",
          wbAmount: "",
          wb: "",
          rate: "",
          price: "",
          count: ""
        },
        {
          type: 0,
          summary: "",
          subjectId: 0,
          fzhsId: 0,
          signName: "",
          outAmount: "",
          inAmount: "",
          wbAmount: "",
          wb: "",
          rate: "",
          price: "",
          count: ""
        },
        {
          type: 0,
          summary: "",
          subjectId: 0,
          fzhsId: 0,
          signName: "",
          outAmount: "",
          inAmount: "",
          wbAmount: "",
          wb: "",
          rate: "",
          price: "",
          count: ""
        },
      ],
      showWb: false,
      showCh: false,
      widthAmount: 13,
      // hisSubjectListPeriod:'',
      focusCtrl: '0_1',  // 自动聚焦控制,变动时,123执行自动聚焦指令
      currentIndex: '0_1', // 当前聚焦元素的索引
      actionType: 'next',
      wbLists:[]
    };
  },
  beforeRouteEnter (to, from, next) {
    if(to.query.type == 'add'){
      to.meta.title = '添加凭证'
    }else if(to.query.type == 'edit'){
      to.meta.title = '编辑凭证'
    }
    next()
  },
  created() {
    let period = this.$store.getters['user/comInfo'].period
    // this.hisSubjectListPeriod = period
    this.getnowTime(period);
    this.init()
  },
  methods: {
    init(){
      let id = this.$store.getters["commons/params"].historyVoucherId
      // let type = this.$store.getters["commons/params"].type
      if(id){
        this.getList(id)
      }else{
        this.keepOn()
        this.showSelect = true
      }
    },
    // 新增时凭证号
    initVoucherNo(){
      getNewVoucherNo({}).then(res=>{
        this.content.voucherNo = res.data.data.info
      })
    },
    getWbList(){
      wbList({}).then(res=>{
        if(res.data.msg == 'success'){
          this.wbLists = res.data.data.list
        }
      })
    },
    keepOn(e) {
      let timeNow = new Date(this.nowTime)
      if(e){
        timeNow = e
      }
      this.templateType = 0;
      this.template1 = false;
      (this.content = {
        inSumAmount: 0,
        outSumAmount: 0,
        bigwrite: "", //大写合计
        voucherDate: timeNow, //时间
        voucherNo: "", //凭证号
        billCount: 1
      }),
        (this.items = [
        {
            wbStatus: false,
            chStatus: false,
            type: 0,
            summary: "",
            subjectId: 0,
            fzhsId: 0,
            signName: "",
            outAmount: "",
            inAmount: "",
            wbAmount: "",
            wb: "",
            rate: "",
            price: "",
            count: ""
          },{
            wbStatus: false,
            chStatus: false,
            type: 0,
            summary: "",
            subjectId: 0,
            fzhsId: 0,
            signName: "",
            outAmount: "",
            inAmount: "",
            wbAmount: "",
            wb: "",
            rate: "",
            price: "",
            count: ""
          },{
            wbStatus: false,
            chStatus: false,
            type: 0,
            summary: "",
            subjectId: 0,
            fzhsId: 0,
            signName: "",
            outAmount: "",
            inAmount: "",
            wbAmount: "",
            wb: "",
            rate: "",
            price: "",
            count: ""
          },
        ]);
       // //console.log(this.items);
        this.initVoucherNo()
    },
    async changeCode(sign,item,from){
      if(!sign){
        return
      }
      await getHisVoucherCodeInfo({id: sign.split('-')[0] * 1}).then(res=>{
        if(res.data.msg == "success"){
          item.balance = res.data.data.amount
        }
      })
      if(from != "禁止"){
        this.enter()
      }
    },
      // this.subjectList.map(v => {
      //   if(item.subjectCode == v.subjectCode){
      //     item.subjectName = v.subjectName
      //   }
      // });
    // },
    changeCount(item) {
      item.type = 2
      item.outAmount = (item.price * item.count).toFixed(2);
      this.compute();
    },
    changeWbAmount(item) {
      item.type = 1
      item.inAmount = (item.wbAmount * item.rate).toFixed(2);
      this.compute();
    },
    changeWb(wb, item) {
      this.wbLists.map(v => {
        if (v.type == wb) {
          item.rate = v.rate;
        }
      });
    },
    changeInput(item, type) {
      if (type == "in") {
        //存货外币
        if (this.radio == 2) {
          if (item.wbAmount) {
            item.rate = (item.inAmount / item.wbAmount).toFixed(4);
          }
        }
        if (this.radio == 3) {
          if (item.count) {
            item.price = (item.inAmount / item.count).toFixed(2);
          }
        }
      } else {
        //存货外币
        if (this.radio == 2) {
          if (item.wbAmount) {
            item.rate = (item.outAmount / item.wbAmount).toFixed(4);
          }
        }
        if (this.radio == 3) {
          if (item.count) {
            item.price = (item.outAmount / item.count).toFixed(2);
          }
        }
      }
    },
     //借贷金额失去焦点
    inputBlur(item, type) {
     /// //console.log(item,type);
      if (isNaN(item.inAmount && item.outAmount)) {
        this.$message({
          type: "error",
          message: "请输入合法金额数"
        });
        return;
      }
      if (type == "in") {
        //console.log("失焦时");
        if (item.inAmount == "=" || item.inAmount == "＝") {
          let amounts = 0;
          this.items.map(v => {
            if (v.inAmount && v.inAmount != "=" && v.inAmount != "＝") {
              amounts = amounts - Number(v.inAmount);
            }
            if (v.outAmount && v.outAmount != "=" && v.outAmount != "＝") {
              amounts = amounts + Number(v.outAmount);
            }
          });
          item.inAmount = amounts.toFixed(2);
        }

        // if (item.type == 1) {
        //   item.balance = (
        //     Number(item.balanceby) + Number(item.inAmount)
        //   ).toFixed(2);
        // } else {
        //   item.balance = (
        //     Number(item.balanceby) - Number(item.inAmount)
        //   ).toFixed(2);
        // }
      } else {
        item.out_type = false;
        if (item.outAmount == "=" || item.outAmount == "＝") {
          let amounts = 0;
          this.items.map(v => {
            /////console.log(v)
            if (v.inAmount && v.inAmount != "=" && v.inAmount != "＝") {
             // //console.log(v.inAmount)
              amounts = amounts + Number(v.inAmount);
            }
            if (v.outAmount && v.outAmount != "=" && v.outAmount != "＝") {
             // //console.log(v.outAmount)
              amounts = amounts - Number(v.outAmount);
            }
          });
          item.outAmount = amounts.toFixed(2);
        }

        // if (item.type == 1) {
        //   item.balance = (
        //     Number(item.balanceby) - Number(item.outAmount)
        //   ).toFixed(2);
        // } else {
        //   item.balance = (
        //     Number(item.balanceby) + Number(item.outAmount)
        //   ).toFixed(2);
        // }
      }
      this.compute();
    },
    compute() {
      //计算
      let in_sum_amount = 0;
      let out_sum_amount = 0;

      this.items.map(v => {
          out_sum_amount += v.outAmount * 1;
          in_sum_amount += v.inAmount * 1;
      });
      in_sum_amount = Number(in_sum_amount).toFixed(2);
      out_sum_amount = Number(out_sum_amount).toFixed(2);
      this.content.inSumAmount = in_sum_amount;
      this.content.outSumAmount = out_sum_amount;
      if (in_sum_amount == out_sum_amount) {
        this.content.bigwrite = this.saveBig(in_sum_amount);
      } else {
        this.content.bigwrite = "";
      }
    },
    saveBig(money) {
      let statusFu = false
      if(money < 0){
        money = -money
        statusFu = true
      }
      //汉字的数字
      var cnNums = new Array(
        "零",
        "壹",
        "贰",
        "叁",
        "肆",
        "伍",
        "陆",
        "柒",
        "捌",
        "玖"
      );
      //基本单位
      var cnIntRadice = new Array("", "拾", "佰", "仟");
      //对应整数部分扩展单位
      var cnIntUnits = new Array("", "万", "亿", "兆");
      //对应小数部分单位
      var cnDecUnits = new Array("角", "分", "毫", "厘");
      //整数金额时后面跟的字符
      var cnInteger = "整";
      //整型完以后的单位
      var cnIntLast = "圆";
      //最大处理的数字
      var maxNum = 999999999999999.9999;
      //金额整数部分
      var integerNum;
      //金额小数部分
      var decimalNum;
      //输出的中文金额字符串
      var chineseStr = "";
      //分离金额后用的数组，预定义
      var parts;
      if (money == "") {
        return "";
      }
      money = parseFloat(money);
      if (money >= maxNum) {
        //超出最大处理数字
        return "";
      }
      if (money == 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger;
        return chineseStr;
      }
      //转换为字符串
      money = money.toString();
      if (money.indexOf(".") == -1) {
        integerNum = money;
        decimalNum = "";
      } else {
        parts = money.split(".");
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 4);
      }
      //获取整型部分转换
      if (parseInt(integerNum, 10) > 0) {
        var zeroCount = 0;
        var IntLen = integerNum.length;
        for (var i = 0; i < IntLen; i++) {
          var n = integerNum.substr(i, 1);
          var p = IntLen - i - 1;
          var q = p / 4;
          var m = p % 4;
          if (n == "0") {
            zeroCount++;
          } else {
            if (zeroCount > 0) {
              chineseStr += cnNums[0];
            }
            //归零
            zeroCount = 0;
            chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
          }
          if (m == 0 && zeroCount < 4) {
            chineseStr += cnIntUnits[q];
          }
        }
        chineseStr += cnIntLast;
      }
      //小数部分
      if (decimalNum != "") {
        var decLen = decimalNum.length;
        for (var i = 0; i < decLen; i++) {
          var n = decimalNum.substr(i, 1);
          if (n != "0") {
            chineseStr += cnNums[Number(n)] + cnDecUnits[i];
          }
        }
      }
      if (chineseStr == "") {
        chineseStr += cnNums[0] + cnIntLast + cnInteger;
      } else if (decimalNum == "") {
        chineseStr += cnInteger;
      }

      if(statusFu){
        chineseStr = "负" + chineseStr
      }

      return chineseStr;
    },
    getSubjectType(code) {
      let type = 0;
      this.subjectList.map(v => {
        if (v.code == code) {
          type = v.type;
        }
      });
      return type;
    },
    showInput(item, type) {
      if (type == "in") {
        if(item.outAmount){
          item.inAmount = item.outAmount
        }
        item.outAmount = "";
        item.type = 1;
       // item.in_type = true;
       // item.out_type = false;
      } else {
        if(item.inAmount){
          item.outAmount = item.inAmount
        }
        item.inAmount = "";
        item.type = 2;
      //  item.in_type = false;
        item.out_type = true;
      }
    },
    // showInput(item, type, index) {
    //   //console.log(type,item);
    //   if (type == "in") {
    //     if(item.outAmount){
    //       item.inAmount = item.outAmount
    //     }
    //     item.outAmount = "";
    //     item.type = 1;
    //     // item.in_type = true;
    //   } else {
    //     if(item.inAmount){
    //       item.outAmount = item.inAmount
    //     }
    //     item.inAmount = "";
    //     item.type = 2;
    //     item.out_type = true;
    //   }
    // },
    handleCreate(e) {
      //console.log(this.content,"xx");
      if(isNaN(Number(this.content.voucherNo)) || Number(this.content.voucherNo)<0){
        this.$qzfMessage('凭证号为数字，请重新输入',1)
        return
      }
      let param = {
        id: this.content.id * 1,
        inSumAmount: Number(Number(this.content.inSumAmount).toFixed(2)),
        outSumAmount: Number(Number(this.content.outSumAmount).toFixed(2)),
        voucherDate: this.content.voucherDate, //时间
        voucherNo: this.content.voucherNo?"记" + this.content.voucherNo:"",
        billCount: this.content.billCount * 1,
        item: this.items,
        period:this.$store.getters["commons/params"].hisVoucherPeriod,
      };
      param.item.map(v => {
        this.subjectList.map(z => {
          if (v.subjectCode == z.subjectCode) {
            v.subjectName = z.subjectName;
          }
        });
        v.type = v.type * 1;
        v.subjectCode = v.subjectCode;
        v.subjectName = v.subjectName
        v.outAmount = v.outAmount * 1;
        v.inAmount = v.inAmount * 1;
        v.inAmountWb = v.type == 1 ? v.wbAmount * 1 : 0;
        v.outAmountWb = v.type == 2 ? v.wbAmount * 1 : 0;
        v.rate = v.rate * 1;
        v.price = v.price * 1;
        v.count = v.count * 1;
      });
      
      if (param.inSumAmount == param.outSumAmount) {
        if (this.templateType == 10) {
          param.type = 0;
        } else {
          param.type = this.templateType
        }
        let status0 = false;
        if (status0) {
          this.$message.error("请补充完整科目名称");
          return;
        }
        this.saveVou(param,e)
      } else {
        this.$notify({
          title: "失败",
          message: "借贷金额不平,请正确填写",
          type: "error",
          duration: 2000
        });
      }
    },
    saveVou(param,e){
      this.fullscreenLoading = true;
      saveHisVoucher(param).then(res => {
        this.fullscreenLoading = false;
        if (res.data.msg != "success") {
          this.$message.error(res.data.msg);
        } else {
          this.$store.dispatch("delCachedViewByName", "voucherhis");
          this.$notify({
            title: "成功",
            type: "success",
            duration: 2000
          });
          if(e){
            this.keepOn(e)     //保存并新增
            return
          }
          this.getList(res.data.data.id)
        }
      });
    },
    delitem(index, id) {
      this.items.splice(index, 1);
      this.compute();
    },
    additem(index) {
      this.items.splice(index + 1, 0, {
        inputStatus: 5,
        in_type: false,
        out_type: false,
        type: 0,
        summary: "",
        subjectCode: "",
        subjectName: "",
        outAmount: "",
        inAmount: "",
        wbAmount: "",
        subjectId: 0,
        fzhsId: 0,
        signName: "",
        wb: "",
        rate: "",
        price: "",
        count: ""
      });
    },
    getList(id) {
      // let id = this.$store.getters["commons/params"].historyVoucherId;
      // //console.log(id,'id')
      // if (!id && this.addType == "add") {
      //   this.items = this.items
      //   return;
      // }
      // if (id && this.addType != "add"){
      //   id = id;
      // }else{
      //   // id = this.$store.getters.params.voucherhis_id;
      //   // return
      // }
      // this.id = id;
      // //console.log(this.$store.getters,'this.$store.getters["commons/params"]')
      hisVoucherInfo({ id: id }).then(res => {
        let voucher = res.data.data.info;
        voucher.voucherNo = voucher.voucherNo.replace("记", "");
        this.year = voucher.period.substring(0, 4);
        this.month = voucher.period.substring(4, 6);
        this.content = voucher;
        let items = voucher.item;
        this.templateType = voucher.type;
        items.map(v => {
          if (v.type == 1) {
            v.wbAmount = v.inAmountWb;
          } else {
            v.wbAmount = v.outAmountWb;
          }
          v.signName = v.fzhsName? v.subjectName + '--辅助核算：' + v.fzhsName:v.subjectName
        })
        this.items = items;
        this.compute();
        this.items.map(v=>{
          this.changeCode(v.subjectId + '-' + v.fzhsId, v, "禁止")
        })
       // //console.log(this.items);
        this.focusCtrl = "0_1" // 自动聚焦控制,变动时,123执行自动聚焦指令
        this.currentIndex = "0_1" // 当前聚焦元素的索引
        this.showSelect = true
      });
    },
    huifu(index,num){
      this.currentIndex = index + '_' + num
      this.focusCtrl = index + '_' + num
      this.actionType = 'jump'
    },
    //摘要
    focusEnter(value){
      if(!value){
        let qian = this.currentIndex.split('_')[0]
        let item = this.items[qian];
        if(this.items[0].summary != ''){
          item.summary = this.items[0].summary
        }
      }
      return
    },
    //平衡借贷金额
    balanceAmount(e, item, type){
      if(e.key == '='){
        e.preventDefault();
        if (type == "in") {
            let amounts = 0;
            item.inAmount = 0
            this.items.map(v => {
              if (v.inAmount) {
                amounts = amounts - Number(v.inAmount);
              }
              if (v.outAmount) {
                amounts = amounts + Number(v.outAmount);
              }
            });
            item.inAmount = amounts.toFixed(2);       
        } else {
          item.out_type = false;
          item.outAmount = 0
          let amounts = 0;
          this.items.map(v => {
            if (v.inAmount) {
              amounts = amounts + Number(v.inAmount);
            }
            if (v.outAmount) {
              amounts = amounts - Number(v.outAmount);
            }
          });
          item.outAmount = amounts.toFixed(2);
        }
      }
    },
    jumpDetailAccount(row){
      this.$store.dispatch("commons/setParam", { paramSubjectId: row.subjectId });
      this.$store.dispatch('tagsView/delCachedViewByName', "history")
      this.$router.push({
        path: "/his/history",
        name: "history",
        params: { subjectId: row.subjectId },
      });
    },
    getnowTime(period) {
      let year = period.substring(0, 4);
      let month = period.substring(4, 6);
      let day = 0;
      if (month == 4 || month == 6 || month == 9 || month == 11) {
        day = 30;
      } else if (month == 2) {
        day = 28;
      } else {
        day = 31;
      }
      this.nowTime = year + "-" + month + "-" + day;
    },
    enter(e){
      // e.preventDefault();
      let qian = this.currentIndex.split('_')[0]
      let hou = this.currentIndex.split('_')[1]
      let item = this.items[qian];
      if(hou == 10){
        if(qian == (this.items.length-1)){
          this.additem(qian)
        }
        this.items[qian * 1 + 1].summary = item.summary;
        this.currentIndex = (Number(qian) + 1) + '_1'
        this.focusCtrl = (Number(qian) + 1) + '_1'
        this.actionType = 'jump'
      }else{
        if(item.wbStatus && hou == 2){//外币可填
          this.currentIndex = qian + "_3";
          this.focusCtrl = qian + "_3";
        }else if(hou == 2 || hou == 5){
          // item.chStatus
          if (false) {
            this.currentIndex = qian + "_6";
            this.focusCtrl = qian + "_6";
          } else {
            if (item.outAmount) {
              this.currentIndex = qian + "_10";
              this.focusCtrl = qian + "_10";
            } else {
              item.type = 1;
              this.currentIndex = qian + "_9";
              this.focusCtrl = qian + "_9";
            }
          }
        }else{
          if (hou == 9 && !item.inAmount) {
            item.type = 2;
            this.currentIndex = qian + "_" + (Number(hou) + 1);
            this.focusCtrl = qian + "_" + (Number(hou) + 1);
            this.actionType = "jump";
          } else if (hou == 9 && item.inAmount) {
            if(qian == (this.items.length-1)){
              this.additem(qian)
            }
            this.items[qian * 1 + 1].summary = item.summary;
            this.currentIndex = Number(qian) + 1 + "_1";
            this.focusCtrl = Number(qian) + 1 + "_1";
            this.actionType = "jump";
          } else if(hou == 7){
            item.type = 1;
              this.currentIndex = qian + "_9";
              this.focusCtrl = qian + "_9";
          } else {
            this.currentIndex = qian + "_" + (Number(hou) + 1);
            this.focusCtrl = qian + "_" + (Number(hou) + 1);
            this.actionType = "jump";
          }
        }
      }
    },
  //   getnowTimelast(period) {
  //     let year = period.substring(0, 4);
  //     let month = period.substring(4, 6) * 1 - 1;
  //     if(month == 0){
  //       month = 12
  //       year = year * 1 - 1
  //     }
  //     let day = 0;
  //     if (month == 4 || month == 6 || month == 9 || month == 11) {
  //       day = 30;
  //     } else if (month == 2) {
  //       day = 28;
  //     } else {
  //       day = 31;
  //     }
  //     dataLast = year + "-" + month + "-" + day;
  //   },
  }
};
</script>

<style lang="scss" scoped>
.app-wrapper{
  overflow:hidden !important;
}
.app-container{
 // height: 630px;
  overflow-x: auto;
}
.select_box_big{
  width: 100%;
  padding:0 5px 5px 5px;
  
  .el-select{
    width: 100% !important;
  }
  .el-input{
    width: 100% !important;
  }
}
.padding_sty {
  border-bottom: 1px solid #eee;
  margin: 5px;
  .top_input {
    border-bottom: none !important;
  }
}
.top_item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  input{
    width: 100% !important;
  }

  .top_input {
    flex: 1;
    height: 24px !important;
    display: inline-block;
    background-color: #fff;
    background-image: none;
    // border-radius: 4px;
    // border-bottom: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    font-size: inherit;
    line-height: 24px;
    outline: none;
    padding: 0 6px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
  }

  .el-select {
    flex: 1;
    display: inline-block;
  }
}
.bottom_rate {
  // width: 100%;
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-bottom: 1px solid #eee;
  label {
    flex: 1;
    font-size: 14px;
    color: #333;
    line-height: 24px;
    padding-left: 10px;
    font-weight: normal;
  }

  input {
    height: 24px !important;
    display: inline-block;
    flex: 1;
    width: 100px;
    // margin: 5px;
    -webkit-appearance: none;
    background-color: #fff;
    background-image: none;
    // border-radius: 4px;
    box-sizing: border-box;
    color: #606266;
    font-size: inherit;
    line-height: 24px;
    outline: none;
    padding: 0 6px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}
.bottom_btn {
  width: 1200px;
  text-align: right;
  margin-top: 30px;
  margin-left: 30px;
}

.bottom_btn2 {
 width: 1200px;
  text-align: right;
  margin-top: 30px;
  margin-left: 30px;
}

.text_box li:nth-child(3) {
  border-color: #f0ffb6;
}
.text_box li:nth-child(6) {
  border-color: #ffedb0;
}
.text_box li:nth-child(9) {
  border-color: #81ff89;
}
.text_box li:nth-child(12) {
  border-color: #9dd7ff;
}
 .big_gsbqw {
  font-size: 0;
  border-top: 1px solid #eee;
  position: relative;
  height: 68px;
  p{
    position: absolute;
    left: 7px;
    top: 0;
    height: 68px;
    line-height: 68px !important;
    font-size: 14px;
    font-weight: normal !important;
    text-align: right !important;
    width: 100%;
  }
  div {
    float: left;
    // width: 7.69%;
    width: calc(100% / 13);
    height: 68px;
    font-size: 14px;
    vertical-align: top;
    border-left: 1px solid #eee;
    // border-top: 1px solid #eee;
  }
  div:nth-child(3) {
    border-color: #FFA9A9;
  }
  div:nth-child(6) {
    border-color: #FFD089;
  }
  div:nth-child(9) {
    border-color: #81ff89;
  }
  div:nth-child(12) {
    border-color: #9dd7ff;
  }
  div:nth-child(1) {
    border-left: none;
  }
}
.td-money {
  width: 100%;
  text-align: center;
  // background-image: url("http://etax.qizhangfang.net/template/eaccount/line1.png");
  // background-repeat: repeat-y;
  // background-size: 110%;
  position: relative;
  input {
    border: none !important;
    background: none !important;
    width: 100%;
    height: 68px;
    letter-spacing: 2px;
    text-align: right;
    font-size: 22px;
  }
  > div {
    width: 100% !important;
    text-align: right;
    height: 100%;
    line-height: 67px;
    // background: #fff;
    font-size: 14px;
  }
  span {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 7px;
    top: 0;
  }
}
.tops {
  font-size: 0;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .add {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    left: -28px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: #f15a24;
    cursor: pointer;
    .svg-icon {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
  .gsbqw {
    font-size: 0;
    border-top: 1px solid #f3f3f3;
    div {
      float: left;
      // width: 7.69%;
      width: calc(100% / 13);
      height: 32px;
      font-size: 14px;
      vertical-align: top;
      border-left: 1px solid #f3f3f3;
      // border-top: 1px solid #eee;
    }
     div:nth-child(3) {
    border-color: #FFA9A9;
  }
  div:nth-child(6) {
    border-color: #FFD089;
  }
  div:nth-child(9) {
    border-color: #81ff89;
  }
  div:nth-child(12) {
    border-color: #9dd7ff;
  }
  div:nth-child(1) {
    border-left: none;
  }
  }
  .box_top {
    // width: calc(30% - 230px);
    // width: 15%;
    // min-width: 15%;
    display: inline-flex;
    flex: 1;
    border-left: 1px solid #666;
    border-bottom: 1px solid #666;
    display: inline-block;
    // display: flex;
    width:25%;
    height: 68px;
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 14px;
    vertical-align: top;
    line-height: 68px;
    h6 {
      font-weight: 600;
      font-size: 14px;
      color: #333;
    }
    > div {
      width: 100%;
      height: 34px;
      line-height: 34px;
      font-weight: 500;
    }
    .money_box_top {
      width: 220px;
    }
  }
}
.tops .box_top:first-child {
  border-left: none !important;
}
.center_title {
  float: left;
  width: 19%;
  margin: 0 auto;
  text-align: center;
  input {
    font-size: 14px;
    color: #333;
    line-height: 36px;
  }
}
.right_num {
  float: right;
  width: 20%;
  text-align: right;
  font-size: 14px;
  color: #333;
}
.content2 {
  width: 100%;
  margin: 0 auto;
  border: 3px solid #999;
  margin-top: 10px;
  position: relative;
}
.bottom_input {
  width: 100%;
  margin: 0 auto;
  margin-top: 30px;
  .item_btn {
    width: 46%;
    margin: 0 auto;
    margin-bottom: 15px;
    position: relative;
    h6 {
      float: left;
      width: 80px;
      font-size: 15px;
      color: #333;
      font-weight: normal;
      line-height: 36px;
      margin-right: 10px;
      text-align: right;
    }
    p {
      float: left;
      line-height: 36px;
      color: #333;
      font-size: 15px;
      font-weight: normal;
    }
    input {
      float: left;
      width: 47%;
      border: none;
      border-bottom: 1px solid #666;
      margin-left: 5px;
      line-height: 22px;
    }
  }
  .left_box {
    width: 46%;
  }
  .right_box {
    width: 46%;
    // text-align: right;
  }
}
.text_big {
  position: relative;
  height: 68px;
  li {
    float: left;
    width: calc(100% / 13);
    height: 68px;
    line-height: 68px;
    text-align: center;
    font-size: 14px;
    color: #333;
    vertical-align: top;
    border-left: 1px solid #f3f3f3;
    z-index: 8;
    position: relative;
    font-weight: bold;
  }
  li:nth-child(1) {
    border-left: none;
  }
  input {
    width: 100%;
    height: 100%;
    background: #fff;
    margin: 0 auto;
    z-index: 9999;
    // box-shadow: 0 2px 10px #ddd;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.bottom {
  width: 100%;
  margin: 0 auto;
  border-bottom: 1px solid #666;
  height: 68px;
  position: relative;
  display: flex;
  p {
    font-size: 14px;
    text-align: center;
    line-height: 56px;
    font-weight: 600;
  }
  .add {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    right: -28px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    color: var(--themeColor,#17a2b8);
    cursor: pointer;
    .svg-icon {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
  .close {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    right: -32px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    color: #F56C6C;
    cursor: pointer;
    .svg-icon {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
}
.bottom:last-child {
  border-bottom: none;
}
.common_box {
  // float: left;
  // width: calc(30% - 230px);
  // min-width: 15%;
  width: 25%;
  flex: 1;
  border-left: 1px solid #666;
  position: relative;
}
.common_box:first-child {
  border-left: 0;
}
.infarm_box {
  // resize: none;
  width: 100%;
  height: 68px;
  font-size: 15px;
  line-height: 22px;
  box-sizing: border-box;
  color: #333;
  border: none;
  margin: 0;
  padding: 5px;
  background: none !important;
}
.big_box3 {
  width: 1200px;
  // height:580px;
  border:1px solid #ddd;
  padding: 20px 60px;
  background: #fff;
  box-shadow: 0 2px 10px #ddd;
  margin: 30px;
  min-height: 500px;
  position: relative;
  .left_sh{
    position: absolute;
    left: 60px;
    top: 20px;
    img{
      width: 100px;
      z-index: 99999999999999;
    }
    .status_true{
      padding: 6px 20px;
      border: 2px solid #67c23a;
      font-size: 14px;
      color: #67c23a;
    }
    .status_false{
      padding: 6px 20px;
      border: 2px solid #f56c6c;
      font-size: 14px;
      color: #f56c6c;
    }
  }
  .moban {
    position: absolute;
    top: 20px;
    right: 50px;
  }
  .arrow_btn {
    // width: 20px;
    // height: 60px;
    text-align: center;
    .two_btn {
      font-size: 20px;
      color: #f15a24;
      line-height: 60px;
      background: #eee;
      cursor: pointer;
    }
    .arrow_left {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    .arrow_right {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .bottom_time {
    float: left;
    width: 40%;
  }
  .top_input2 {
    width: 50%;
    h6 {
      display: inline-block;
      width: 40px;
      font-size: 14px;
      font-weight: normal;
    }
  }
  .top_input3 {
    width: 30%;
    h6 {
      float: left;
      font-size: 15px;
      color: #333;
      font-weight: normal;
      line-height: 26px;
      margin-right: 10px;
    }
    span {
      font-size: 15px;
      color: #333;
      font-weight: normal;
      line-height: 26px;
      margin-left: 10px;
    }
    input {
      float: left;
      width: 47%;
      border: none;
      border-bottom: 1px solid #666;
      margin-left: 5px;
      line-height: 22px;
    }
  }
}

.top_title {
  width: 100%;
  margin: 25px auto;
  position: relative;
  h5 {
    font-size: 24px;
    color: #333;
    text-align: center;
    font-weight: 500;
    margin-bottom: 25px;
  }
  .tags_title{
    font-size: 15px !important;
    color: #666;
    margin-left: 50px;
    position: absolute;
    right: 30%;
    top: 10%;
  }
  .radio_box{
    position: absolute;
    top: 6px;
    left:0;
  }
  
}
.boss {
  display: inline-block;
  margin-bottom: 20px;
  position: relative;
  .iconfont icon-cuowu{
    position: absolute;
    right: 2px;
    top: 2px;
    color: #f56c6c;
  }
  .boss-list{
    padding: 5px 10px;
    font-size: 14px;
    border: 1px solid #dcdfe6;
    border-radius: 2px;
    margin-right: 10px;
    margin-top: 10px;
    cursor: pointer;
  }
}
.select_box {
  position: relative;
  .yuer {
    position: absolute;
    bottom: 0;
    color: #777;
    font-size: 10px;
    .money {
      color: #409eff !important;
    }
  }
  .select_subject {
    // width: 200px;
    width: 100%;
    height: 68px;
    border: none;
  }
  .uls {
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 9999999999;
    display: block;
    height: 230px;
    background: #efefef;
    width: 450px;
    overflow: auto;

    ul {
      position: absolute;
      left: 0;
      li {
        line-height: 25px;
        cursor: pointer;
        overflow: hidden; /*超出部分隐藏*/
        text-overflow: ellipsis; /* 超出部分显示省略号 */
        white-space: nowrap; /*规定段落中的文本不进行换行 */
      }
    }
  }
}
.cur {
  background: #f0ffb6;
}
.xinzheng {
  width: 450px;
  height: 30px;
  line-height: 30px;
  background: #f15a24;
  color: #fff;
  text-align: center;
  border: 1px solid #f15a24;
  position: absolute;
  top: 278px;
  left: 0;
  z-index: 99999999999;
  cursor: pointer;
}
.button_bg {
  .item {
    margin-bottom: 10px;
  }
}
.inventory {
  margin-left: 30px;
  table {
    border-color: 1px #f15a24 solid;
    tr {
      line-height: 30px;
      td {
        width: 100px;
        text-align: center;
      }
    }
  }
}
.yuer {
  position: absolute;
  bottom: 0;
  color: #777;
  font-size: 10px;
  z-index: 1000;
  .money {
    color: #409eff !important;
  }
}
.yuer:hover{
  color: #1114da !important;
  cursor: pointer;
}
</style>
<style>
 .common_box_div .el-select .select-trigger .el-input__inner {
  border: 0;
  padding: 32px 0px;
}
</style>

